<!-- catch unhandled errors and display them in an <error-queue-snackbar> component -->

<template>
	<div>
		<slot/>
		<error-queue-snackbar ref="errors" :timeout="timeout" :color="color"></error-queue-snackbar>
	</div>
</template>

<script>
import ErrorQueueSnackbar from "@/ittijs/components/ErrorQueueSnackbar";
export default {
	components: {ErrorQueueSnackbar},
	props: {
		timeout: {
			type: Number,
			default: 5000,
		},
		color: {
			type: String,
			default: "red",
		},
	},
	methods: {
		onError(err){
			if (typeof err === 'object' && err.errors && err.errors.form) {
				for (const item of err.errors.form) this.$refs.errors.push(item);
			}
			else {
				this.$refs.errors.push(err);
			}
		},
	},
	errorCaptured(err) {
		this.onError(err);
		return true;
	},
	provide(){
		return {
			throwError: err => {
				this.onError(err);
			},
		}
	},
}
</script>