import {map as _map, debounce} from "lodash";
import {Map} from "core-js/internals/map-helpers";

export function itemsForSelect(items){
	// always return an array of objects, with keys 'value' and 'text'
	// this is the format recognized by v-select
	if (Array.isArray(items)) {
		if (items.length > 0 && typeof(items[0])==='string')  {
			return _map(items, function (v, k) {
				if (typeof v === 'object') {
					return {
						...v,
						value: k.toString(),
					}
				}
				return {
					value: k.toString(),
					text: v,
				};
			});
		}
		else {
			return items;
		}
	}
	if (typeof items === "object") {
		return _map(items, function (v, k) {
			if (typeof v === 'object') {
				return {
					...v,
					value: k,
				}
			}
			return {
				value: k,
				text: v,
			};
		});
	}
	return [];
}

/**
 * files is an object, keys are form names, values are either a single File,
 * a FileList, or an array of File objects.
 * progressCallback is directly attached as listener of the progress event
 * and receives a ProgressEvent argument.
 * @param url
 * @param files
 * @param formData
 * @param progressCallback
 * @return {{abort: any, promise: Promise<unknown>}}
 */
export function uploadWithProgress(url, files, formData, progressCallback) {
	const xhr = new XMLHttpRequest();
	const promise = new Promise((resolve, reject) => {
		const fdata = formData || new FormData;
		for (const n in files) {
			const f = files[n];
			// console.log(n, f);
			if (f instanceof File) {
				fdata.append(n, f);
			}
			else if (f instanceof FileList) {
				for (const file of f) {
					fdata.append(n+'[]', file);
				}
			}
			else if (Array.isArray(f)) {
				for (const file of f) {
					if (file instanceof File) {
						fdata.append(n+'[]', file);
					}
				}
			}
		}
		// console.log(fdata);
		// fetch doesn't support tracking upload progress - use XMLHttpRequest
		if (typeof progressCallback === 'function') xhr.upload.addEventListener('progress', progressCallback, false);
		xhr.open("POST", url);
		xhr.withCredentials = true;
		xhr.onabort = function(event){
			reject({
				status: null,
				statusText: 'aborted',
				event: event,
			});
		};
		xhr.onerror = function(event){
			reject({
				status: null,
				statusText: 'error',
				event: event,
			});
		};
		xhr.onload = function(event){
			if (xhr.status === 200) {
				try {
					resolve(JSON.parse(xhr.response));
				}
				catch(error){
					reject({
						status: null,
						statusText: 'json error',
						response: xhr.response,
						event,
					});
				}
			}
			else {
				try {
					reject({
						status: xhr.status,
						statusText: xhr.statusText,
						response: JSON.parse(xhr.response),
						event,
					});
				}
				catch (error) {
					reject({
						status: xhr.status,
						statusText: xhr.statusText,
						response: xhr.response,
						event,
					});
				}
			}
		};
		xhr.send(fdata);
	});
	return {
		promise,
		abort: xhr.abort.bind(xhr),
	};
}

export function formatBytes($size) {
	let $unit = 'bytes', $prec = 0;

	if ($size>1024) {
		$size /= 1024;
		$unit = 'KB';
	}

	if ($size>1024) {
		$size /= 1024;
		$unit = 'MB';
	}

	if ($size>1024) {
		$size /= 1024;
		$unit = 'GB';
	}

	if ($size<10) $prec = 2;
	if ($size<100) $prec = 1;

	return Math.round($size, $prec) + ' ' + $unit;
}

const parseBytesMap = {
	b:  1,
	k: 1000,
	ki: 1 << 10,
	m: Math.pow(1000, 2),
	mi: 1 << 20,
	g: Math.pow(1000, 3),
	gi: 1 << 30,
	t: Math.pow(1000, 4),
	ti: Math.pow(1024, 4),
	p: Math.pow(1000, 5),
	pi: Math.pow(1024, 5),
};
const parseBytesRegExp = /^((-|\+)?(\d+(?:\.\d+)?)) *([kmgtp]i?)b?$/i;

/**
 * Extracted from https://github.com/visionmedia/bytes.js
 * Added more unit support - missing 'b', decimal/binary variants
 * @param val
 * @returns {number|null}
 */
export function parseBytes(val) {
	if (typeof val === 'number' && !isNaN(val)) {
		return val;
	}

	if (typeof val !== 'string') {
		return null;
	}

	// Test if the string passed is valid
	const results = parseBytesRegExp.exec(val);
	let floatValue;
	let unit = 'b';

	if (!results) {
		// Nothing could be extracted from the given string
		floatValue = parseInt(val, 10);
		unit = 'b'
	} else {
		// Retrieve the value and the unit
		floatValue = parseFloat(results[1]);
		unit = results[4].toLowerCase();
	}

	if (isNaN(floatValue)) {
		return null;
	}

	return Math.floor(parseBytesMap[unit] * floatValue);
}

/* eslint-disable prefer-const */

export function phpDate(format, timestamp) {
	//  discuss at: https://locutus.io/php/date/
	// original by: Carlos R. L. Rodrigues (https://www.jsfromhell.com)
	// original by: gettimeofday
	//    parts by: Peter-Paul Koch (https://www.quirksmode.org/js/beat.html)
	// improved by: Kevin van Zonneveld (https://kvz.io)
	// improved by: MeEtc (https://yass.meetcweb.com)
	// improved by: Brad Touesnard
	// improved by: Tim Wiel
	// improved by: Bryan Elliott
	// improved by: David Randall
	// improved by: Theriault (https://github.com/Theriault)
	// improved by: Theriault (https://github.com/Theriault)
	// improved by: Brett Zamir (https://brett-zamir.me)
	// improved by: Theriault (https://github.com/Theriault)
	// improved by: Thomas Beaucourt (https://www.webapp.fr)
	// improved by: JT
	// improved by: Theriault (https://github.com/Theriault)
	// improved by: Rafał Kukawski (https://blog.kukawski.pl)
	// improved by: Theriault (https://github.com/Theriault)
	//    input by: Brett Zamir (https://brett-zamir.me)
	//    input by: majak
	//    input by: Alex
	//    input by: Martin
	//    input by: Alex Wilson
	//    input by: Haravikk
	// bugfixed by: Kevin van Zonneveld (https://kvz.io)
	// bugfixed by: majak
	// bugfixed by: Kevin van Zonneveld (https://kvz.io)
	// bugfixed by: Brett Zamir (https://brett-zamir.me)
	// bugfixed by: omid (https://locutus.io/php/380:380#comment_137122)
	// bugfixed by: Chris (https://www.devotis.nl/)
	//      note 1: Uses global: locutus to store the default timezone
	//      note 1: Although the function potentially allows timezone info
	//      note 1: (see notes), it currently does not set
	//      note 1: per a timezone specified by date_default_timezone_set(). Implementers might use
	//      note 1: $locutus.currentTimezoneOffset and
	//      note 1: $locutus.currentTimezoneDST set by that function
	//      note 1: in order to adjust the dates in this function
	//      note 1: (or our other date functions!) accordingly
	//   example 1: date('H:m:s \\m \\i\\s \\m\\o\\n\\t\\h', 1062402400)
	//   returns 1: '07:09:40 m is month'
	//   example 2: date('F j, Y, g:i a', 1062462400)
	//   returns 2: 'September 2, 2003, 12:26 am'
	//   example 3: date('Y W o', 1062462400)
	//   returns 3: '2003 36 2003'
	//   example 4: var $x = date('Y m d', (new Date()).getTime() / 1000)
	//   example 4: $x = $x + ''
	//   example 4: var $result = $x.length // 2009 01 09
	//   returns 4: 10
	//   example 5: date('W', 1104534000)
	//   returns 5: '52'
	//   example 6: date('B t', 1104534000)
	//   returns 6: '999 31'
	//   example 7: date('W U', 1293750000.82); // 2010-12-31
	//   returns 7: '52 1293750000'
	//   example 8: date('W', 1293836400); // 2011-01-01
	//   returns 8: '52'
	//   example 9: date('W Y-m-d', 1293974054); // 2011-01-02
	//   returns 9: '52 2011-01-02'
	//        test: skip-1 skip-2 skip-5
	let jsdate, f
	// Keep this here (works, but for code commented-out below for file size reasons)
	// var tal= [];
	const txtWords = [
		'Sun', 'Mon', 'Tues', 'Wednes', 'Thurs', 'Fri', 'Satur',
		'January', 'February', 'March', 'April', 'May', 'June',
		'July', 'August', 'September', 'October', 'November', 'December'
	]
	// trailing backslash -> (dropped)
	// a backslash followed by any character (including backslash) -> the character
	// empty string -> empty string
	const formatChr = /\\?(.?)/gi
	const formatChrCb = function (t, s) {
		return f[t] ? f[t]() : s
	}
	const _pad = function (n, c) {
		n = String(n)
		while (n.length < c) {
			n = '0' + n
		}
		return n
	}
	f = {
		// Day
		d: function () {
			// Day of month w/leading 0; 01..31
			return _pad(f.j(), 2)
		},
		D: function () {
			// Shorthand day name; Mon...Sun
			return f.l()
				.slice(0, 3)
		},
		j: function () {
			// Day of month; 1..31
			return jsdate.getDate()
		},
		l: function () {
			// Full day name; Monday...Sunday
			return txtWords[f.w()] + 'day'
		},
		N: function () {
			// ISO-8601 day of week; 1[Mon]..7[Sun]
			return f.w() || 7
		},
		S: function () {
			// Ordinal suffix for day of month; st, nd, rd, th
			const j = f.j()
			let i = j % 10
			if (i <= 3 && parseInt((j % 100) / 10, 10) === 1) {
				i = 0
			}
			return ['st', 'nd', 'rd'][i - 1] || 'th'
		},
		w: function () {
			// Day of week; 0[Sun]..6[Sat]
			return jsdate.getDay()
		},
		z: function () {
			// Day of year; 0..365
			const a = new Date(f.Y(), f.n() - 1, f.j())
			const b = new Date(f.Y(), 0, 1)
			return Math.round((a - b) / 864e5)
		},
		// Week
		W: function () {
			// ISO-8601 week number
			const a = new Date(f.Y(), f.n() - 1, f.j() - f.N() + 3)
			const b = new Date(a.getFullYear(), 0, 4)
			return _pad(1 + Math.round((a - b) / 864e5 / 7), 2)
		},
		// Month
		F: function () {
			// Full month name; January...December
			return txtWords[6 + f.n()]
		},
		m: function () {
			// Month w/leading 0; 01...12
			return _pad(f.n(), 2)
		},
		M: function () {
			// Shorthand month name; Jan...Dec
			return f.F()
				.slice(0, 3)
		},
		n: function () {
			// Month; 1...12
			return jsdate.getMonth() + 1
		},
		t: function () {
			// Days in month; 28...31
			return (new Date(f.Y(), f.n(), 0))
				.getDate()
		},
		// Year
		L: function () {
			// Is leap year?; 0 or 1
			const j = f.Y()
			return j % 4 === 0 & j % 100 !== 0 | j % 400 === 0
		},
		o: function () {
			// ISO-8601 year
			const n = f.n()
			const W = f.W()
			const Y = f.Y()
			return Y + (n === 12 && W < 9 ? 1 : n === 1 && W > 9 ? -1 : 0)
		},
		Y: function () {
			// Full year; e.g. 1980...2010
			return jsdate.getFullYear()
		},
		y: function () {
			// Last two digits of year; 00...99
			return f.Y()
				.toString()
				.slice(-2)
		},
		// Time
		a: function () {
			// am or pm
			return jsdate.getHours() > 11 ? 'pm' : 'am'
		},
		A: function () {
			// AM or PM
			return f.a()
				.toUpperCase()
		},
		B: function () {
			// Swatch Internet time; 000..999
			const H = jsdate.getUTCHours() * 36e2
			// Hours
			const i = jsdate.getUTCMinutes() * 60
			// Minutes
			// Seconds
			const s = jsdate.getUTCSeconds()
			return _pad(Math.floor((H + i + s + 36e2) / 86.4) % 1e3, 3)
		},
		g: function () {
			// 12-Hours; 1..12
			return f.G() % 12 || 12
		},
		G: function () {
			// 24-Hours; 0..23
			return jsdate.getHours()
		},
		h: function () {
			// 12-Hours w/leading 0; 01..12
			return _pad(f.g(), 2)
		},
		H: function () {
			// 24-Hours w/leading 0; 00..23
			return _pad(f.G(), 2)
		},
		i: function () {
			// Minutes w/leading 0; 00..59
			return _pad(jsdate.getMinutes(), 2)
		},
		s: function () {
			// Seconds w/leading 0; 00..59
			return _pad(jsdate.getSeconds(), 2)
		},
		u: function () {
			// Microseconds; 000000-999000
			return _pad(jsdate.getMilliseconds() * 1000, 6)
		},
		// Timezone
		e: function () {
			// Timezone identifier; e.g. Atlantic/Azores, ...
			// The following works, but requires inclusion of the very large
			// timezone_abbreviations_list() function.
			/*              return that.date_default_timezone_get();
			 */
			const msg = 'Not supported (see source code of date() for timezone on how to add support)'
			throw new Error(msg)
		},
		I: function () {
			// DST observed?; 0 or 1
			// Compares Jan 1 minus Jan 1 UTC to Jul 1 minus Jul 1 UTC.
			// If they are not equal, then DST is observed.
			const a = new Date(f.Y(), 0)
			// Jan 1
			const c = Date.UTC(f.Y(), 0)
			// Jan 1 UTC
			const b = new Date(f.Y(), 6)
			// Jul 1
			// Jul 1 UTC
			const d = Date.UTC(f.Y(), 6)
			return ((a - c) !== (b - d)) ? 1 : 0
		},
		O: function () {
			// Difference to GMT in hour format; e.g. +0200
			const tzo = jsdate.getTimezoneOffset()
			const a = Math.abs(tzo)
			return (tzo > 0 ? '-' : '+') + _pad(Math.floor(a / 60) * 100 + a % 60, 4)
		},
		P: function () {
			// Difference to GMT w/colon; e.g. +02:00
			const O = f.O()
			return (O.substr(0, 3) + ':' + O.substr(3, 2))
		},
		T: function () {
			// The following works, but requires inclusion of the very
			// large timezone_abbreviations_list() function.
			/*              var abbr, i, os, _default;
			if (!tal.length) {
				tal = that.timezone_abbreviations_list();
			}
			if ($locutus && $locutus.default_timezone) {
				_default = $locutus.default_timezone;
				for (abbr in tal) {
					for (i = 0; i < tal[abbr].length; i++) {
						if (tal[abbr][i].timezone_id === _default) {
							return abbr.toUpperCase();
						}
					}
				}
			}
			for (abbr in tal) {
				for (i = 0; i < tal[abbr].length; i++) {
					os = -jsdate.getTimezoneOffset() * 60;
					if (tal[abbr][i].offset === os) {
						return abbr.toUpperCase();
					}
				}
			}
			*/
			return 'UTC'
		},
		Z: function () {
			// Timezone offset in seconds (-43200...50400)
			return -jsdate.getTimezoneOffset() * 60
		},
		// Full Date/Time
		c: function () {
			// ISO-8601 date.
			return 'Y-m-d\\TH:i:sP'.replace(formatChr, formatChrCb)
		},
		r: function () {
			// RFC 2822
			return 'D, d M Y H:i:s O'.replace(formatChr, formatChrCb)
		},
		U: function () {
			// Seconds since UNIX epoch
			return jsdate / 1000 | 0
		}
	}
	const _date = function (format, timestamp) {
		jsdate = (timestamp === undefined ? new Date() // Not provided
				: (timestamp instanceof Date) ? new Date(timestamp) // JS Date()
					: new Date(timestamp * 1000) // UNIX timestamp (auto-convert to int)
		)
		return format.replace(formatChr, formatChrCb)
	}
	return _date(format, timestamp)
}

export function formatDate(str, format) {
	if (str==='0000-00-00' || str==='0000-00-00 00:00:00') return '';
	if (!format) format = window.ITTIConfig && window.ITTIConfig.dateFormat || 'j.n.Y г.';
	const match = str.match(/^(\d{4})-(\d{2})-(\d{2})$/);
	if (match) {
		const date = new Date(parseInt(match[1]), parseInt(match[2])-1, parseInt(match[3]));
		return phpDate(format, date);
	}
	return str;
}

export function formatDateTime(str, format) {
	if (str==='0000-00-00' || str==='0000-00-00 00:00:00') return '';
	if (!format) format = window.ITTIConfig && window.ITTIConfig.dateTimeFormat || 'H:i j.n.Y г.';
	const match = str.match(/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/);
	if (match) {
		const date = new Date(
			parseInt(match[1]),
			parseInt(match[2])-1,
			parseInt(match[3]),
			parseInt(match[4]),
			parseInt(match[5]),
			parseInt(match[6]),
		);
		return phpDate(format, date);
	}
	return str;
}

/**
 * Tracks the progress and transfer speed of ProgressEvent.
 * queue_length is the length (in events) of the sliding window that will be
 * used to calculate transfer speed.
 */
export class ProgressSpeed {

	constructor(queueLength){
		this.events = [];
		this.qlen = queueLength;
		this.loaded = 0;
		this.total = 0;
		this.progress = 0; // between 0 and 1
		this.speed = 0; // bps
		this.timeRemaining = Infinity; // seconds
	}

	push(event) {
		if (!event.lengthComputable) return;

		this.events.push(event);
		if (this.events.length > this.qlen) {
			this.events.shift();
		}

		// percent progress
		this.loaded = event.loaded;
		this.total = event.total;
		this.progress = event.loaded / event.total;

		// upload speed, time remaining
		if (this.events.length > 1) {
			const key = Math.min(this.qlen, this.events.length) - 1;
			const diffTime = this.events[key].timeStamp - this.events[0].timeStamp;
			const diffLoaded = this.events[key].loaded - this.events[0].loaded;
			this.speed = 1000 * diffLoaded / diffTime;
			this.timeRemaining = diffTime * (event.total - event.loaded) / diffLoaded / 1000;
		}
	}

}

export function fileToMaterialIcon(name, mimetype){ // pick a material design icon for a file
	if (typeof name !== 'string') name = '';
	if (typeof mimetype !== 'string') mimetype = '';

	switch (name.toLowerCase().split('.').pop()) {
		case 'txt':
			return 'mdi-file-document';
		case 'jpeg':
		case 'jpg':
		case 'png':
		case 'gif':
		case 'svg':
			return 'mdi-file-image';
		case 'doc':
		case 'docx':
			return 'mdi-file-word';
		case 'xls':
		case 'xlsx':
			return 'mdi-file-excel';
		case 'ppt':
		case 'pptx':
			return 'mdi-file-powerpoint';
		case 'pdf':
			return 'mdi-file-pdf';
		case 'zip':
		case 'rar':
		case '7z':
			return 'mdi-folder-zip';
	}

	switch (mimetype) {
		case 'application/pdf':
			return 'mdi-file-pdf';
		case 'application/zip':
		case 'application/x-7z-compressed':
		case 'application/x-rar-compressed':
			return 'mdi-folder-zip';
	}

	if (mimetype.match(/^image\//)) {
		return 'mdi-file-image';
	}
	if (mimetype.match(/^video\//)) {
		return 'mdi-file-video';
	}
	if (mimetype.match(/^audio\//)) {
		return 'mdi-file-music';
	}
	if (mimetype.match(/^sound\//)) {
		return 'mdi-file-music';
	}
	if (mimetype.match(/^text\//)) {
		return 'mdi-file-document';
	}
	return null;
}

export function isoToDate(isostring) {
	if (!isostring) return null;
	if (isostring==='0000-00-00') return null;
	let parsed = isostring.match(/^(\d{4})-(\d{2})-(\d{2})$/);
	if (parsed===null) {
		parsed = isostring.match(/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/);
	}
	else {
		parsed[4] = 0;
		parsed[5] = 0;
		parsed[6] = 0;
	}
	if (parsed===null) return null;
	const date = new Date;
	date.setFullYear(parseInt(parsed[1]), parseInt(parsed[2])-1, parseInt(parsed[3]));
	date.setHours(parsed[4], parsed[5], parsed[6], 0);
	return date;
}

/**
 * Смята разлика в показанието, като игнорира NaN в старите показания и в vNew2
 * @return {number}
 */
export function valueDiff(vOld, vNew, vOld2, vNew2) {
	vOld = parseFloat(vOld);
	vNew = parseFloat(vNew);
	vOld2 = parseFloat(vOld2);
	vNew2 = parseFloat(vNew2);

	if (isNaN(vOld)) vOld = 0;
	if (isNaN(vOld2)) vOld2 = 0;

	let diff = vNew - vOld;
	if (!isNaN(vNew2)) diff += vNew2 - vOld2;

	return diff;
}

class MyDataTransfer {
	constructor() {
		return new ClipboardEvent("").clipboardData || new DataTransfer();
	}
}

export function arrayToFileList(fileArray) {
	if (!Array.isArray(fileArray)) return (new DataTransfer).files;
	const dt = new MyDataTransfer();

	for (const file of fileArray) {
		dt.items.add(file)
	}

	if (dt.files.length) {
		return dt.files; // set `FileList` of `dt.files`: `DataTransfer.files` to `input.files`
	}

	return (new DataTransfer).files;
}

/**
 * Sum a number of string numbers by passing them through parseFloat and ignoring NaN ones.
 * @param nums
 * @returns Number
 */
export function floatSum(...nums) {
	return nums.reduce((sum, num) => sum + (parseFloat(num) || 0.0), 0.0);
}

/**
 * Render discount percent based on the original and discounted prices.
 * If price is not smaller than orig, return empty string.
 * @param orig
 * @param price
 * @param decimals
 * @returns {string|null}
 */
export function calcDiscountPercent(orig, price, decimals) {
	if (!orig) return null;
	price = parseFloat(price) || 0.0;
	if (price >= orig) return null;
	return (100 - 100 * price / orig).toFixed(decimals) + ' %';
}

/**
 * Used mainly for unique color assignment, to queue up a bunch of colors
 * and then assign a new one to each unique key that is requested.
 */
export class KeyQueue {
	constructor(queue = []) {
		if (!Array.isArray(queue)) throw "queue must be array";
		this.queue = [...queue];
		this.map = new Map;
		this.spentQueue = [];
		this.infinite = false; // rewind when queue is empty
	}
	getByKey(key) {
		if (this.map.has(key)) {
			return this.map.get(key);
		}
		if (this.queue.length===0 && this.infinite && this.spentQueue.length > 0) {
			this.queue = [...this.spentQueue];
			this.spentQueue = [];
		}
		if (this.queue.length===0) {
			return null;
		}
		let item = this.queue.shift();
		this.map.set(key, item);
		this.spentQueue.push(item);
		return item;
	}
}

export class TimeOfDay {

	/**
	 * @member {number}
	 */
	#minutes;

	/**
	 * @param {number} minutes
	 */
	constructor(minutes = 0) {
		if (minutes >= 24 * 60) {
			console.log(minutes, 24*60);
			throw "time overflow";
		}
		this.#minutes = minutes;
	}

	/**
	 * @param {string} value
	 * @returns {TimeOfDay|null}
	 */
	static fromString(value) {
		let match = value.toString().substring(0, 5).match(/^(\d{1,2}):(\d{2})$/);
		if (match) {
			let hours = parseInt(match[1]);
			let minutes = parseInt(match[2]);
			if (hours >= 24) return null;
			if (minutes >= 60) return null;
			return new TimeOfDay(hours * 60 + minutes);
		}
		return null;
	}

	/**
	 * @param {TimeOfDay} time
	 * @returns {TimeOfDay}
	 */
	add(time) {
		return new TimeOfDay(this.#minutes + time.#minutes);
	}

	/**
	 * @param {number} minutes
	 * @returns {TimeOfDay}
	 */
	addMinutes(minutes) {
		return new TimeOfDay(this.#minutes + minutes);
	}

	toString() {
		return Math.floor(this.#minutes / 60).toString().padStart(2, '0')
			+ ':'
			+ (this.#minutes % 60).toString().padStart(2, '0');
	}

	/**
	 * @returns {number}
	 */
	minutes() {
		return this.#minutes;
	}

}

export class TimeOfDayInterval {

	/**
	 * @member {TimeOfDay}
	 */
	#start;

	/**
	 * @member {TimeOfDay}
	 */
	#end;

	/**
	 * @param {TimeOfDay} start
	 * @param {TimeOfDay} end
	 */
	constructor(start, end) {
		if (start.minutes() > end.minutes()) {
			throw "start is after end";
		}
		this.#start = start;
		this.#end = end;
	}

	/**
	 * @returns {TimeOfDay}
	 */
	getStart() {
		return this.#start;
	}

	/**
	 * @returns {TimeOfDay}
	 */
	getEnd() {
		return this.#end;
	}

	/**
	 * @param {string} start
	 * @param {string} end
	 * @returns {TimeOfDayInterval}
	 */
	static fromString(start, end) {
		return new TimeOfDayInterval(
			TimeOfDay.fromString(start),
			TimeOfDay.fromString(end),
		);
	}

	/**
	 * @returns {number}
	 */
	minutes() {
		return this.#end.minutes() - this.#start.minutes();
	}

}

/**
 * Debounce an async function, and don't let another call until the previous resolves.
 */
export function debounceAsync(func, wait) {
	if (typeof func != 'function') {
		throw new TypeError('Expected a function');
	}

	let promise = null;
	let isPending = false;
	let queueParams = null;
	let queued = false;

	const onFinally = () => {
		isPending = false;
		if (queued) {
			promise = func(...queueParams);
			isPending = true;
			promise.finally(onFinally);
			queueParams = null;
			queued = false;
		}
	};

	return debounce((...params) => {
		if (isPending && promise) {
			queued = true;
			queueParams = params;
		}
		else {
			promise = func(...params);
			isPending = true;
			promise.finally(onFinally);
		}
		return promise;
	}, wait);
}

export function stationMapUrl(station, city, address) {
	if(!station){
		return null;
	}
	if (station.gps_lat && station.gps_lng) {
		return 'https://www.google.com/maps/dir/?api=1&destination=' + encodeURIComponent(`${station.gps_lat},${station.gps_lng}`);
	}
	return 'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(
		city + ', ' + address
	);
}