/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	isoToDate,
	itemsForSelect,
	phpDate,
	uploadWithProgress
} from "@/ittijs/utils";

const ITTIConfig = (window as any).ITTIConfig;

class Field {
	textAlign='left';

	formatView(value: any){
		return value;
	}
}

class Numeric extends Field {
	textAlign='right';
}

class DateTime extends Field {

	constructor(protected format: string) {
		super();
	}

	formatView(value: string) {
		const parsed = isoToDate(value);
		if (!parsed) return '';
		return phpDate(this.format, parsed);
	}

}

class Bool extends Field {
	formatView(value: any) {
		if (value==='0') value = false;
		if (value==='1') value = true;
		if (value==='true') value = true;
		if (value==='yes') value = true;
		if (value==='on') value = true;
		if (value==='false') value = false;
		if (value==='no') value = false;
		if (value==='off') value = false;
		return value ? 'Да' : 'Не';
	}
}

class Enum extends Field {

	constructor(protected options: Array<ModelOptionsItem>) {
		super();
	}

	formatView(value: any) {
		for (const o of this.options) {
			if (o.value == value) {
				return o.text;
			}
		}
		return value;
	}

}

class Set extends Enum {

	formatView(value: any) {
		if (typeof(value)==='string') {
			value = value.split(',');
		}
		if (!Array.isArray(value)) {
			throw "array or string expected";
		}
		return this.options
			.filter(o=>value.indexOf(o.value)>-1)
			.map(o=>o.text)
			.join(', ');
	}

}

export class ITTIModelBase {

	Title: string | null;
	fetchOptions = {
		credentials: "include",
	}

	constructor(public modelName: string, public apiURL?: string) {
		this.Title = null;
		if (!this.apiURL) {
			this.apiURL = ITTIConfig.RESTEndPointURL;
		}
	}

	/**
	 * Convert a model (received from api) to a field object
	 * @param model
	 */
	static model2field(model: FieldModel) {
		switch (model.type) {
			case 'integer':  return new Numeric();
			case 'decimal':  return new Numeric();
			case 'float':  return new Numeric();
			case 'date':
				return new DateTime(ITTIConfig.dateFormat);
			case 'datetime':
				return new DateTime(ITTIConfig.dateTimeFormat);
			case 'boolean':
				return new Bool;
			case 'enum':
				return new Enum(itemsForSelect(model.options));
			case 'set':
				return new Set(itemsForSelect(model.options));
			case 'string':
			default:
				return new Field;
		}
	}

	preProcessFields(fields: any){

		if(typeof(fields)=='string'){
			fields = fields.split(/[^a-zA-Z0-9_-]/).map(e=>e.trim()).filter(e=>!!e);
		}

		if(Array.isArray(fields)){

			const res: any={};
			for(let el of fields){

				if(typeof(el)==='string'){
					el = {
						name: el,
						type: "string"
					};
				}

				if (typeof(el)==='object' && el.name) {
					res[el.name] = el;
				}
				else {
					throw new Error('Invalid Field definition: '+JSON.stringify(el));
				}

			}

			fields = res;
		}

		if (typeof fields === 'object') {
			for (const k in fields) {
				if (typeof fields[k] === 'object' && fields[k].type) {
					fields[k].model = ITTIModelBase.model2field(fields[k]);

				}
			}
		}

		/*
				for(const el of fields){
					if(!el.title) el.title=el.name;
				}
		*/
		return fields;

	}

	getRowById(id: number|string){
		return this.fetch('getRow', {id});
	}

	getRow(params: object){
		return this.fetch('getRow', params);
	}

	validate(data: object){

		console.log(data);

	}

	// async save(id: number|string|null, data: object){
	//   return await this.fetch('saveRow', {id}, {id, data});
	// }

	save(params: object, data: object){
		// spread params to be compatible with save(), but may change in the
		return this.fetch('saveRow', params, {data});
	}

	delete(params: object){
		return this.fetch('deleteRow', {}, params);
	}

	deleteList(paramList: Array<object>){
		return this.fetch('deleteRows', {}, paramList);
	}

	getList(ListOptions: ListOptions){
		return this.fetch("getList", ListOptions);
	}

	exportList(ListOptions: ListOptions){
		return this.fetch("exportList", ListOptions);
	}

	uploadWithProgress(method: string, urlParams: object, files: object, formData: any, progressCallback: Function): {promise: Promise<any>; abort: Function} {
		const p = new URLSearchParams({
			class: this.modelName,
			method: method,
			params: JSON.stringify(urlParams),
		});
		return uploadWithProgress(this.apiURL+'?'+p.toString(), files, formData, progressCallback);
	}

	mfUpload(files: FileList|File, progressCallback: Function): {promise: Promise<any>; abort: Function} {
		return this.uploadWithProgress('mfUpload', {}, {files}, null, progressCallback);
	}

	async mfInfo(ids: Array<string>): Promise<Array<ManagedFile>> {
		return await this.fetch('mfInfo', {ids});
	}

	getRequestURL(method: string, params={}) {
		const p = new URLSearchParams({class:this.modelName, method, params:JSON.stringify(params)});
		return this.apiURL+'?'+p.toString();
	}

	/**
	 * If you want to abort the request, give a function as getController.
	 * The function will be called with an AbortController parameter which
	 * can be used to abort the request.
	 * @param method
	 * @param params
	 * @param postData
	 * @param getController
	 */
	fetch(method: string, params={}, postData: object|null = null, getController: Function|null = null){
		const ctrl = new AbortController();
		if (getController) getController(ctrl);
		const init: any = {
			...this.fetchOptions,
			signal: ctrl.signal,
		};
		if (postData!==null) {
			init.method = "POST";
			init.body = JSON.stringify(postData);
		}
		const res = fetch(this.getRequestURL(method, params), init);
		return res.then(async r=>{
			if (r.ok) {
				return await r.json();
			}
			else {
				if (r.status === 400) {
					throw await r.json();
				}
				if (r.status === 401) {
					window.location.href = ITTIConfig.LoginURL + '?r=' + encodeURIComponent(window.location.href);
				}
			}
		});
	}

}

export default class ITTIModel extends ITTIModelBase {

	loaded = false;
	fields: FieldsList = {};
	fieldsAsync: null | Promise <FieldsList> = null;
	modelData: null | Record<string, any> = null;

	constructor(
		modelName: string,
		apiURL?: string,
	)
	{
		super(modelName, apiURL);
		this.fieldsAsync = new Promise((done: Function) =>{
			this.fetch('getModelJS')
				.then(d=>{
					this.modelData = d;
					if(!this.Title) this.Title = d.title;
					this.fields = this.preProcessFields(d.fields);
					done(this.fields);
				})
				.finally(()=>{
					this.loaded = true;
				})
			;
		});
	}

	getListHeader(name: string) {
		if (this.fields[name]) {
			return {
				value: name,
				text: this.fields[name].label,
				align: this.fields[name].model.textAlign,
			}
		}
		return null;
	}

	getListHeaders(keys: any){

		if(typeof(keys)=='string'){
			keys = keys.split(/[^a-zA-Z0-9_.-]/).map(e=>e.trim()).filter(e=>!!e);
		}

		const h: any = {};
		let fields = this.fields;

		if (Array.isArray(keys)) {
			fields = {};
			for (const k of keys) {
				if (this.fields[k]) fields[k] = this.fields[k];
			}
		}

		for(const k in fields){
			const fld = fields[k];
			if(fld.skipInList) continue;
			h[k] = {value:k, text:fld.label};
			h[k].align = fld.model.textAlign;
		}

		return h;
	}

	getLabel(name: string) {
		return this.fields[name] && this.fields[name].label || '';
	}

	getLabels(names: Array<string>) {
		return names.map(name => this.getLabel(name));
	}

	formatData(data: Record<string,any>) {
		const result: any = {};
		for (const k in data) {
			result[k] = data[k];

			if (this.fields && this.fields[k] && this.fields[k].model) {
				if (data[k]!==null) {
					result[k] = this.fields[k].model.formatView(data[k]);
				}
			}
		}
		return result;
	}

	getEditFieldsTpl(){
		return this.fields;
	}

}

interface FieldsList {
	[key: string]: FieldModel;
}

interface FieldModel {
	name: string;
	label: string;
	groupTitle?: string;
	type: string;
	options: Array<ModelOptionsItem>;
	model: Field;
	skipInSearch?: boolean;
	skipInList?: boolean;
	skipInEdit?: boolean;
}

interface ListOptions {
	cols?: Array<string>;
	sort?: string[];
	limit?: number;
	offset?: number;
	filter?: FilterGroup | string;
	params?: any;
}

export const enum FilterOps {
	EQUALS          = 'eq',
	EQUALS_NOT      = 'ne',
	LOWER           = 'lt',
	LOWER_OR_EQUAL  = 'le',
	GREATER         = 'gt',
	GREATER_EQUAL   = 'ge',
	BEGINS          = 'bw',
	BEGINS_NOT      = 'bn',
	IN              = 'in',
	NOT_IN          = 'ni',
	ENDS            = 'ew',
	ENDS_NOT        = 'en',
	CONTAINS        = 'cn',
	CONTAINS_NOT    = 'nc',
	EMPTY           = 'em',
	NULL            = 'nu',
	KEYWORDS        = 'kw',
	FIND_IN_SET_ALL = 'fs',
	FIND_IN_SET_ANY = 'fa',
	BETWEEN         = 'be',
	FIND_IN_FILES   = 'ff',
	TRUE            = 'yy',
	FALSE           = 'nn',
}

export interface FilterRule {
	field: string;
	op: FilterOps;
	value: any;
	not?: boolean;
}

export const enum FilterGroupOps {
	AND = "AND",
	OR = "OR",
}

export interface FilterGroup {
	rules: Array<FilterRule>;
	groups?: Array<FilterGroup>;
	glue?: FilterGroupOps;
	not?: boolean;
}

interface ModelOptionsItem {
	text: string | number | object;
	value: string | number | object;
	disabled?: boolean;
	divider?: boolean;
	header?: string;
}

interface ManagedFile {
	id: string;
	md5: string;
	size: number|string;
	name: string;
	type: string;
	uploaded_date: string;
	url: string;
	thumb: string;
}
