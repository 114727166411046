<template>
	<v-app>
		<network-state-snackbar top />
		<error-snackbar-catcher>
			<v-navigation-drawer v-if="userProfile" app clipped v-model="drawer" mobile-breakpoint="sm">
				<NavMenu nav dense :struct="menu">
					<template #top>
						<v-list-item>
							<v-list-item-avatar size="32" color="primary" class="mr-6">
								<v-icon color="white">mdi-account-circle</v-icon>
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title>{{ userProfile.username }}</v-list-item-title>
								<v-list-item-subtitle>{{ userProfile.group }}</v-list-item-subtitle>
							</v-list-item-content>
							<v-list-item-action>
								<v-btn small outlined fab color="grey" link :href="logoutUrl">
									<v-icon>mdi-logout-variant</v-icon>
								</v-btn>
							</v-list-item-action>
						</v-list-item>
						<v-divider></v-divider>
					</template>
					<template #bottom v-if="searchRouteName">

						<v-list-item>
							<v-text-field
								label="Имот номер:"
								hide-details="auto"
								v-model="searchImot"
								ref="searchImotInput"
								hint="[Enter] за търсене"
								:loading="searchImotLoading"
								:error-messages="searchImotError"
								@keypress.enter.exact.prevent="doSearchImot"
								@change="searchImotError = ''"
								@blur="searchImotError = ''"
							>
								<template #append>
									<v-btn small icon @click="doSearchImot">
										<v-icon>mdi-magnify</v-icon>
									</v-btn>
								</template>
							</v-text-field>
						</v-list-item>

						<v-list-item>
							<v-text-field
								label="Уред номер:"
								hide-details="auto"
								v-model="searchDevice"
								ref="searchDeviceInput"
								hint="[Enter] за търсене"
								:loading="searchDeviceLoading"
								:error-messages="searchDeviceError"
								@keypress.enter.exact.prevent="doSearchDevice"
								@change="searchDeviceError = ''"
								@blur="searchDeviceError = ''"
							>
								<template #append>
									<v-btn small icon @click="doSearchDevice">
										<v-icon>mdi-magnify</v-icon>
									</v-btn>
								</template>
							</v-text-field>
						</v-list-item>

					</template>
				</NavMenu>
			</v-navigation-drawer>

			<v-app-bar v-if="userProfile" app color="headings" clipped-left elevate-on-scroll>
				<v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

				<div class="d-flex align-center">
					НЕЛБО
				</div>

				<v-switch
					hide-details class="ml-4" color="primary"
					v-model="$vuetify.theme.dark"
					label="Тема"
				/>

				<v-spacer/>

				<v-select
					dense
					solo-inverted
					hide-details
					clearable
					v-model="reactiveProvide.utility"
					:items="reactiveProvide.utilityOptions"
					item-text="name"
					placeholder="Дружество:"
					style="flex: 0 0 200px;"
					:menu-props="{maxHeight:600}"
				/>

			</v-app-bar>

			<v-main v-if="userProfile">
				<router-view />
			</v-main>

		</error-snackbar-catcher>
	</v-app>
</template>

<script>
import Vue from "vue";
import Authorization from "@/ittijs/Authorization";
import {RolesEnum} from "@/ittijs/Authorization";
import NavMenu from "@/ittijs/NavMenu/NavMenu.vue";
import nav from "./nav";
import {getNav} from "./nav-lib";
import ModelUtility from "./views/utility/Model";
import { itemsForSelect } from "@/ittijs/utils";
import ModelImotData from "./views/imot_data/Model";
import {ITTIModelBase} from "@/ittijs/ITTIModel";
import ErrorSnackbarCatcher from "@/ittijs/components/ErrorSnackbarCatcher";
import NetworkStateSnackbar from "@/ittijs/components/NetworkStateSnackbar.vue";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";

const imotDataModel = ITTIModelProvider.getModel(ModelImotData);

class AuthModel extends ITTIModelBase {
	constructor() {
		super('Auth');
	}
}

const authModel = new AuthModel;

export default Vue.extend({
	name: "NelboERP",

	provide(){
		return {
			authorization: this.auth,
			reactive: this.reactiveProvide,
			getUserProfile: () => this.userProfile,
		};
	},

	components: {
		NetworkStateSnackbar,
		ErrorSnackbarCatcher,
		NavMenu,
	},

	async created(){
		this.userProfile = await authModel.fetch('getProfile');
		this.auth.setRoles(this.userProfile && this.userProfile.rights || []);
		const t = new ModelUtility;
		t.getSelectOptions().then(res=>{
			this.reactiveProvide.utilityOptions = itemsForSelect(res);
		});
		this.$vuetify.theme.dark = localStorage.getItem('darkMode') === '1';
	},

	data: () => ({
		userProfile: null,
		auth: new Authorization([]),
		drawer: sessionStorage.getItem("be-drawer") === "1" || sessionStorage.getItem("be-drawer") === null,
		menu: getNav(nav),
		reactiveProvide: {
			utility: localStorage.getItem('utilitySelection') || null,
			utilityOptions: [],
			devListHideHeating: localStorage.getItem("devListHideHeating") === "1",
			devListHideColdWater: localStorage.getItem("devListHideColdWater") === "1",
		},

		searchImot: '',
		searchImotLoading: false,
		searchImotError: '',

		searchDevice: '',
		searchDeviceLoading: false,
		searchDeviceError: '',

	}),

	computed: {
		logoutUrl: ()=>window.ITTIConfig.LogoutURL,
		searchRouteName(){
			if (this.auth.hasAnyRole([RolesEnum.OTCHETI_BASE,RolesEnum.OTCHETI_FULL])) {
				return 'account';
			}
			if (this.auth.hasRole(RolesEnum.FRONTDESK)) {
				return 'frontdesk';
			}
			return null;
		},
	},

	watch: {
		drawer(val) {
			sessionStorage.setItem("be-drawer", val ? "1" : "");
		},
		"reactiveProvide.utility"(val) {
			localStorage.setItem("utilitySelection", val ? val : "");
		},
		"reactiveProvide.devListHideHeating"(val) {
			localStorage.setItem("devListHideHeating", val ? "1" : "");
		},
		"reactiveProvide.devListHideColdWater"(val) {
			localStorage.setItem("devListHideColdWater", val ? "1" : "");
		},
		"$vuetify.theme.dark"(val) {
			localStorage.setItem("darkMode", val ? "1" : "");
		},
	},

	methods: {
		async doSearchImot(){
			if (this.searchImotLoading) return;

			if (!this.reactiveProvide.utility) {
				this.searchImotError = "Изберете дружество";
				return;
			}

			this.searchImotLoading = true;
			const res = await imotDataModel.searchImotNo(this.reactiveProvide.utility, this.searchImot);
			this.searchImotLoading = false;
			if (res === null) {
				//this.$refs.searchImotInput
				this.searchImotError = "Няма такъв";
			}
			else if (this.searchRouteName) {
				this.searchImotError = '';
				this.$router.push({
					name: this.searchRouteName,
					params: {
						utility: this.reactiveProvide.utility,
						session: res.session,
						station: res.station,
						imot: res.imot,
					},
				});
			}
		},
		async doSearchDevice(){
			if (this.searchDeviceLoading) return;

			if (!this.reactiveProvide.utility) {
				this.searchDeviceError = "Изберете дружество";
				return;
			}

			this.searchDeviceLoading = true;
			const res = await imotDataModel.searchDeviceNo(this.reactiveProvide.utility, this.searchDevice);
			this.searchDeviceLoading = false;
			if (res === null) {
				//this.$refs.searchDeviceInput
				this.searchDeviceError = "Няма такъв";
			}
			else if (this.searchRouteName) {
				this.searchDeviceError = '';
				this.$router.push({
					name: this.searchRouteName,
					params: {
						utility: this.reactiveProvide.utility,
						session: res.session,
						station: res.station,
						imot: res.imot,
					},
				});
			}
		},
	},
});
</script>
