
import Vue from 'vue'
import Item from './item.vue'
import {MenuItem} from "@/nav-lib";

interface MenuItemList {
	[index: number]: MenuItem;
}

export default Vue.extend({
	components: {
		Item,
	},
	inject: ['authorization'],
	props:{
		struct: Array as () => MenuItemList,
	},
	computed: {
		filteredStruct(){
			if (this.struct) {
				return (this.struct as Array<MenuItem>).filter((this as any).authorization.filterByRequirements('roles', true));
			}
			return [];
		},
	},
})
