import {RouteConfig} from 'vue-router';
import {Dictionary} from "vue-router/types/router";
import {RolesRequirements} from "@/ittijs/Authorization";

export interface Nav {
	name?: string;
	title?: string;
	icon?: string;
	href?: string;
	params?: Dictionary<string>;
	reuseParams?: boolean; // navigation from the menu will keep existing parameter values
	roles?: RolesRequirements;
	separator?: boolean;
}

/**
 * This is what's given to the NavMenu component
 */
export interface MenuItem extends Nav {
	children: Array<MenuItem>;
}

export interface Item {
	children?: Array<Item>;
	nav?: Nav;
	route?: RouteConfig;
}

export interface ItemList {
	[index: number]: Item;
}

interface ComponentItemParams {
	title: string;
	icon?: string;
	href: string;
	componentProvider: Function;
	roles?: RolesRequirements;
}

interface ComponentRouteLinkItemParams {
	title: string;
	name: string;
	icon?: string;
	href: string;
	path: string;
	reuseParams?: boolean; // navigation from the menu will keep existing parameter values
	componentProvider: Function;
	roles?: RolesRequirements;
}


/**
 * convert a navigation struct to a list of RouteConfig objects
 * @param struct
 * @param result
 */
export function getRoutes(struct: Item[], result: RouteConfig[] = []): RouteConfig[] {
	for (const item of struct) {
		if (item.route) result.push(item.route);
		if (item.children) {
			getRoutes(item.children, result);
		}
	}
	return result;
}

export function makeGroup(title: string, icon: string|undefined, children: Item[]): Item {
	return {
		nav: {
			title,
			icon,
		},
		children: children,
	};
}

/**
 * Adds an optional :id parameter in the href
 */
export function makeDefaultItem(p: ComponentItemParams): Item {
	return {
		nav: {
			title: p.title,
			icon: p.icon,
			href: p.href,
			roles: p.roles,
		},
		route: {
			path: p.href + "/:id?",
			component: p.componentProvider,
		},
	};
}

/**
 * Simple static link, no parameters
 */
export function makeComponentLink(p: ComponentItemParams): Item {
	return {
		nav: {
			title: p.title,
			icon: p.icon,
			href: p.href,
			roles: p.roles,
		},
		route: {
			path: p.href,
			component: p.componentProvider,
		},
	};
}

export function makeParamComponentLink(p: ComponentRouteLinkItemParams): Item {
	return {
		nav: {
			title: p.title,
			icon: p.icon,
			href: p.href,
			name: p.name,
			roles: p.roles,
			reuseParams: p.reuseParams,
		},
		route: {
			name: p.name,
			path: p.path,
			component: p.componentProvider,
			props: true,
		},
	};
}

export const sep = { nav: { separator: true } }; // separator item

/**
 * convert a navigation struct to menu struct
 * @param struct
 */
export function getNav(struct: Item[]): Array<MenuItem> {
	const result = [];
	for (const item of struct) {
		if (item.nav) {
			const nav: MenuItem = {...item.nav} as MenuItem;
			if (item.children) {
				nav.children = getNav(item.children);
			}
			result.push(nav);
		}
	}
	return result;
}
