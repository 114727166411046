<template>
	<v-snackbar
		v-bind="$attrs"
		v-on="$listeners"
		ref="snackbar"
		:timeout="timeout"
		v-model="open"
		:color="color"
	>
		<v-icon v-if="icon" class="mr-2">{{ icon }}</v-icon>
		{{ message }}
	</v-snackbar>
</template>

<script>
export default {
	props: {
		msgOffline: {
			type: String,
			default: 'Няма връзка с интернет!',
		},
		msgOnline: {
			type: String,
			default: 'Връзката е възстановена',
		},
		timeoutOnline: {
			type: Number,
			default: 2000,
		},
	},
	data(){
		return {
			online: navigator.onLine,
			open: !navigator.onLine,
		}
	},
	computed: {
		timeout(){
			return this.online ? this.timeoutOnline : -1;
		},
		color(){
			return this.online ? 'success' : 'error';
		},
		message(){
			return this.online ? this.msgOnline : this.msgOffline;
		},
		icon(){
			return this.online ? 'mdi-wifi-check' : 'mdi-wifi-off';
		},
	},
	watch: {
		online(val, old) {
			if (!val && old) {
				this.open = true;
			}
		},
	},
	methods: {
		updateStatus(e){
			this.online = e.type === 'online';
		},
	},
	mounted() {
		window.addEventListener('online', this.updateStatus);
		window.addEventListener('offline', this.updateStatus);
	},
	beforeDestroy() {
		window.removeEventListener('online', this.updateStatus);
		window.removeEventListener('offline', this.updateStatus);
	},
}
</script>