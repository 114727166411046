
/**
 * PHP: config/nomenclatures.php
 */
export const enum RolesEnum {
	USERS                    = 'users',
	USERS_GROUPS             = 'users_groups',
	SETTINGS                 = 'settings',
	ALL_UTILITIES            = 'all_utilities',
	DEVICE_MODELS            = 'device_models',
	UTILITY                  = 'utility',
	AREA_STATIONS            = 'area_stations',
	STATIONS                 = 'stations',
	SESSIONS                 = 'sessions',
	SESSION_CREATE           = 'session_create',
	SESSION_CURRENT          = 'session_current',
	OTCHETNI_LISTOVE         = 'otchetni_listove',
	SEARCH                   = 'search',
	REPORTS                  = 'reports',
	TEMPERATURES             = 'temperatures',
	FRONTDESK                = 'frontdesk',
	OTCHETI_BASE             = 'otcheti_base',
	OTCHETI_FULL             = 'otcheti_full',
	UVEDOMITELNI_PISMA       = 'uvedomitelni_pisma',
	UVEDOMITELNI_PISMA_FIXED = 'uvedomitelni_pisma_fixed',
	SERVICES                 = 'services',
	MONTAJNI_GRUPI           = 'montajni_grupi',
	MONTAJNIK                = 'montajnik',
	OTCHETNIK                = 'otchetnik',
	OTCHET_PROGRESS          = 'otchet_progress',
	JOB_REQUESTS             = 'job_requests',
	JOB_REQUESTS_DELETE      = 'job_requests_delete',
	MONTAJI_SPRAVKI          = 'montaji_spravki',
	TALON_PLOMBI             = 'talon_plombi',
	TICKET_READONLY          = 'ticket_readonly',
	TICKET_NORMAL            = 'ticket_normal',
	TICKET_ADMIN             = 'ticket_admin',
}

/**
 * Contains list of roles that a user has
 */
export default class Roles {
	constructor(private roles: Array<RolesEnum>){
	}
	/**
	 * Do we have a specific role
	 */
	hasRole(role: RolesEnum): boolean {
		return this.roles.indexOf(role) > -1;
	}
	/**
	 * Do we have at least one of the given roles
	 */
	hasAnyRole(roles: Array<RolesEnum>): boolean {
		return roles.some(this.hasRole.bind(this));
	}
	/**
	 * Do we have all the given roles
	 */
	hasAllRoles(roles: Array<RolesEnum>): boolean {
		return roles.every(this.hasRole.bind(this));
	}
	setRoles(roles: Array<RolesEnum>) {
		this.roles = roles;
	}
	getRoles() {
		return [...this.roles];
	}
	/**
	 * Return a callback function that filters a collection of items.
	 * Each item must have a property containing a role (string)
	 */
	filter(propName: string): (item: { [p: string]: RolesEnum }) => boolean {
		return (item: {[key: string]: RolesEnum}) => this.hasRole(item[propName]);
	}
	/**
	 * Return a callback function that filters a collection of items.
	 * Each item must have a property containing a list of roles.
	 */
	filterAny(propName: string): (item: { [p: string]: Array<RolesEnum> }) => boolean {
		return (item: {[key: string]: Array<RolesEnum>}) => this.hasAnyRole(item[propName]);
	}
	/**
	 * Return a callback function that filters a collection of items.
	 * Each item must have a property containing a list of roles.
	 */
	filterAll(propName: string): (item: { [p: string]: Array<RolesEnum> }) => boolean {
		return (item: {[key: string]: Array<RolesEnum>}) => this.hasAllRoles(item[propName]);
	}
	/**
	 * Return a callback function that filters a collection of items.
	 * Each item may have a property containing a RolesRequirements object.
	 * If this property doesn't exist, defaultResult is returned to the filter.
	 */
	filterByRequirements(
		propName: string,
		defaultResult = false,
	): (item: { [p: string]: RolesRequirements }) => boolean {
		return (item: {[key: string]: RolesRequirements}) => item[propName] ? item[propName].check(this) : defaultResult;
	}
}

/**
 * contains list of roles required by a resource
 */
export class RolesRequirements {
	constructor(
		private roles: Array<RolesEnum>,
		private all: boolean,
	) {
	}
	check(roles: Roles){
		return this.all ? roles.hasAllRoles(this.roles) : roles.hasAnyRole(this.roles);
	}
}

export function RolesAll(roles: Array<RolesEnum>) {
	return new RolesRequirements(roles, true);
}

export function RolesAny(roles: Array<RolesEnum>) {
	return new RolesRequirements(roles, false);
}
