<template>
	<v-snackbar
		multi-line
		:value="errors.length > 0"
		:timeout="-1"
		:color="color"
		content-class="text-body-1"
	>
		<slot name="before" />
		<div v-for="(msg, index) in errors" :key="index">{{msg}}</div>
		<slot name="after" />
		<template #action>
			<v-btn @click="dismiss" :color="color + ' darken-3'" >Затвори</v-btn>
		</template>
	</v-snackbar>
</template>

<script>
export default {
	name: "ErrorQueueSnackbar",
	props: {
		timeout: {
			type: Number,
			default: 5000,
		},
		color: {
			type: String,
			default: "red",
		},
	},
	data(){
		return {
			errors: [],
			timeouts: [],
		}
	},
	methods: {
		push(error){
			this.errors.push(error);
			this.timeouts.push(setTimeout(this.shift.bind(this), this.timeout));
		},
		shift(){
			this.errors.shift();
			this.timeouts.shift();
		},
		dismiss(){
			this.timeouts.map(clearTimeout);
			this.errors = [];
			this.timeouts = [];
		},
	},
}
</script>