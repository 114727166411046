
import ITTIModel from './ITTIModel';

export default class ITTIModelProvider {

	static models: Array<ITTIModel> = [];

	/**
	 * Receives a model constructor as parameter, creates a new instance for the cache if it doesn't exist yet,
	 * and returns the instance.
	 * @param modelClass
	 */
	static getModel(modelClass: new () => ITTIModel): ITTIModel {
		for (const model of ITTIModelProvider.models) {
			if (model.constructor === modelClass) {
				return model;
			}
		}
		const model = new modelClass();
		ITTIModelProvider.models.push(model);
		return model;
	}

}
