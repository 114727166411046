<template>
	<component :is="component" :item="item" :sub-group="subGroup"></component>
</template>
<script>
import {VDivider} from "vuetify/lib/components"

export default {
	name: "nav-menu-item",
	components: {
		VDivider,
		NavMenuGroup: ()=>import('./group.vue'),
		NavMenuLeaf: ()=>import('./leaf.vue'),
	},
	props: {
		item: Object,
		subGroup: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		component(){
			if (this.item.separator) return 'v-divider';
			if (!this.item.children) return 'NavMenuLeaf';
			if (!Array.isArray(this.item.children)) return 'NavMenuLeaf';
			return this.item.children.length ? 'NavMenuGroup' : 'NavMenuLeaf';
		},
	},
}
</script>