import Vue from 'vue';
import Vuetify, {colors} from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        headings: colors.blue.lighten3,
        headings2: colors.grey.lighten2,
        headings3: colors.yellow.lighten4,
      },
      dark: {
        headings: colors.blue.darken4,
        headings2: colors.grey.darken2,
        headings3: colors.grey.darken3,
      },
    },
  },
});
