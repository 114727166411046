import ITTIModel from '../../ittijs/ITTIModel';
export default class ImotData extends ITTIModel {
	constructor() {
		super('ImotData');
	}
	async saveImotNotes(id, notes){
		return await this.fetch('saveImotNotes', {id}, {notes});
	}
	async saveDataNotes(id, notes){
		return await this.fetch('saveDataNotes', {id}, {notes});
	}
	async searchImotNo(utility, imotNo){
		return await this.fetch('searchImotNo', {utility, imotNo});
	}
	async searchDeviceNo(utility, deviceNo){
		return await this.fetch('searchDeviceNo', {utility, deviceNo});
	}
	setZaVizOtchet(session, imot, station, value){
		return this.fetch('setZaVizOtchet', {session, imot, station, value}, {});
	}
	setNeotcheten(session, imot, station, value){
		return this.fetch('setNeotcheten', {session, imot, station, value}, {});
	}
	setReklamacia(session, imot, station, value){
		return this.fetch('setReklamacia', {session, imot, station, value}, {});
	}
	searchImotSession(utility, station, imot) {
		// similar to searchImotNo, but uses station and imot ID
		return this.fetch('searchImotSession', {utility, station, imot});
	}
}