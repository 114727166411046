import {Item, makeComponentLink, makeDefaultItem, makeGroup, makeParamComponentLink,} from "@/nav-lib";
import {RolesAll, RolesAny, RolesEnum} from "@/ittijs/Authorization";

export default [
	makeComponentLink({
		title: 'Настройки на системата',
		icon: 'mdi-cog',
		href: '/settings',
		componentProvider: () => import('./views/settings/Index.vue'),
		roles: RolesAny([RolesEnum.SETTINGS]),
	}),
	makeDefaultItem({
		title: 'Модели у-ва',
		icon: 'mdi-home-thermometer',
		href: '/device_models',
		componentProvider: () => import('./views/device_models/Index.vue'),
		roles: RolesAny([RolesEnum.DEVICE_MODELS]),
	}),
	makeGroup('Монтажи', 'mdi-tools', [
		makeDefaultItem({
			title: 'Списък услуги',
			icon: 'mdi-store',
			href: '/services',
			componentProvider: () => import('./views/montaji/services/Index.vue'),
			roles: RolesAny([RolesEnum.SERVICES]),
		}),
		makeDefaultItem({
			title: 'Монтажни групи',
			icon: 'mdi-toolbox-outline',
			href: '/montajni_grupi',
			componentProvider: () => import('./views/montaji/montajni_grupi/Index.vue'),
			roles: RolesAny([RolesEnum.MONTAJNI_GRUPI]),
		}),
		makeDefaultItem({
			title: 'График',
			icon: 'mdi-calendar-month-outline',
			href: '/montaji_grafik',
			componentProvider: () => import('./views/montaji/schedule/Index.vue'),
			roles: RolesAny([RolesEnum.MONTAJNI_GRUPI]),
		}),
		makeDefaultItem({
			title: 'Заявки',
			icon: 'mdi-calendar-month-outline',
			href: '/montaji_zaiavki',
			componentProvider: () => import('./views/montaji/job_requests/Index.vue'),
			roles: RolesAny([RolesEnum.JOB_REQUESTS]),
		}),
		makeComponentLink({
			title: 'Справки',
			icon: 'mdi-alert-circle-outline',
			href: '/montaji_spravki',
			componentProvider: () => import('./views/montaji/spravki/Index.vue'),
			roles: RolesAny([RolesEnum.MONTAJI_SPRAVKI]),
		}),
	]),
	makeParamComponentLink({
		title: 'Дневен график',
		name: 'montajnikUI',
		icon: 'mdi-tools',
		href: '/montajnik',
		path: '/montajnik/:utility?/:date?/:id?',
		reuseParams: false,
		componentProvider: () => import('./views/montaji/montajnik_ui/Index.vue'),
		roles: RolesAny([RolesEnum.MONTAJNIK]),
	}),
	makeParamComponentLink({
		title: 'Годишни отчети',
		name: 'otchetnikUIProgress',
		icon: 'mdi-card-text',
		href: '/otchetnik_full',
		path: '/otchetnik_full/:utility?/:session?/:date?/:station?/:imot?',
		reuseParams: false,
		componentProvider: () => import('./views/otcheti/otchetnik_ui/Progress.vue'),
		roles: RolesAny([RolesEnum.OTCHET_PROGRESS]),
	}),
	makeParamComponentLink({
		title: 'График годишен отчет',
		name: 'otchetnikUI',
		icon: 'mdi-card-text',
		href: '/otchetnik',
		path: '/otchetnik/:utility?/:session?/:date?/:station?/:imot?',
		reuseParams: false,
		componentProvider: () => import('./views/otcheti/otchetnik_ui/Index.vue'),
		roles: RolesAny([RolesEnum.OTCHETNIK]),
	}),
	makeDefaultItem({
		title: 'Дружества',
		icon: 'mdi-factory',
		href: '/utility',
		componentProvider: () => import('./views/utility/Index.vue'),
		roles: RolesAny([RolesEnum.UTILITY]),
	}),
	makeComponentLink({
		title: 'Температури',
		icon: 'mdi-home-thermometer',
		href: '/temperatures',
		componentProvider: () => import('./views/temperatures/Index.vue'),
		roles: RolesAny([RolesEnum.TEMPERATURES]),
	}),
	makeDefaultItem({
		title: 'Абонатни станции',
		icon: 'mdi-pipe',
		href: '/stations',
		componentProvider: () => import('./views/stations/Index.vue'),
		roles: RolesAny([RolesEnum.STATIONS]),
	}),
	{
		route: {
			name: 'areaStationsGroups',
			path: '/area_stations/:group/:utility?',
			component: () => import('./views/area_stations/Index.vue'),
			props: true,
		},
	},
	makeGroup('Райони', 'mdi-group',
		[
			['monthly', 'Мес. отчет'],
			['yearly', 'Год. отчет'],
			['montaji', 'Монтажи'],
		].map(i => ({
			nav: {
				title: i[1],
				name: 'areaStationsGroups',
				params: {
					group: i[0],
					title: i[1],
				},
				reuseParams: true,
				roles: RolesAny([RolesEnum.AREA_STATIONS]),
			}
		}))
	),
	makeParamComponentLink({
		title: 'Отчетни листове',
		name: 'otchetniListove',
		icon: 'mdi-calendar-alert',
		href: '/otchetni_listove',
		path: '/otchetni_listove/:utility?',
		reuseParams: true,
		componentProvider: () => import('./views/otchetni_listove/Index.vue'),
		roles: RolesAny([RolesEnum.OTCHETNI_LISTOVE]),
	}),
	makeDefaultItem({
		title: 'Сесии',
		icon: 'mdi-calendar-multiple',
		href: '/sessions',
		componentProvider: () => import('./views/sessions/Index.vue'),
		roles: RolesAny([RolesEnum.SESSIONS]),
	}),
	makeParamComponentLink({
		title: 'Нова сесия',
		name: 'createNewSession',
		icon: 'mdi-calendar-plus',
		href: '/session_create',
		path: '/session_create/:utility?',
		reuseParams: true,
		componentProvider: () => import('./views/sessions/Create.vue'),
		roles: RolesAny([RolesEnum.SESSION_CREATE]),
	}),
	makeParamComponentLink({
		title: 'Текуща сесия',
		name: 'sessionCurrent',
		icon: 'mdi-calendar-alert',
		href: '/session_current',
		path: '/session_current/:utility?/:session?',
		reuseParams: true,
		componentProvider: () => import('./views/sessions/Current.vue'),
		roles: RolesAny([RolesEnum.SESSION_CURRENT]),
	}),
	makeGroup('Уведом. писма', 'mdi-email-alert-outline', [
		makeParamComponentLink({
			title: 'Имоти за УП',
			name: 'upReport',
			icon: 'mdi-email-alert-outline',
			href: '/up/report/',
			path: '/up/report/:utility?',
			reuseParams: true,
			componentProvider: () => import('./views/uvedomitelni_pisma/Report.vue'),
			roles: RolesAny([RolesEnum.UVEDOMITELNI_PISMA]),
		}),
		makeParamComponentLink({
			title: 'Търсене',
			name: 'upSearch',
			icon: 'mdi-email-search-outline',
			href: '/up/search/',
			path: '/up/search/:utility?',
			reuseParams: true,
			componentProvider: () => import('./views/uvedomitelni_pisma/Search.vue'),
			roles: RolesAny([RolesEnum.UVEDOMITELNI_PISMA]),
		}),
	]),
	makeGroup('Справки', 'mdi-database-search-outline', [
		makeParamComponentLink({
			title: 'Проверки',
			name: 'reportChecks',
			icon: 'mdi-database-alert-outline',
			href: '/report/checks/',
			path: '/report/checks/:utility?/:session?',
			reuseParams: true,
			componentProvider: () => import('./views/report/checks/Index.vue'),
			roles: RolesAny([RolesEnum.REPORTS]),
		}),
		makeParamComponentLink({
			title: 'Сравняване на сесии',
			name: 'sessionCompare',
			icon: 'mdi-compare',
			href: '/report/sessionCompare/',
			path: '/report/sessionCompare/:utility?',
			reuseParams: true,
			componentProvider: () => import('./views/report/sessionCompare/Index.vue'),
			roles: RolesAny([RolesEnum.REPORTS]),
		}),
		makeParamComponentLink({
			title: 'Списък абонатни станции',
			name: 'reportStationDataList',
			icon: 'mdi-pipe',
			href: '/report/station-list',
			path: '/report/station-list/:utility?/:session?',
			reuseParams: true,
			componentProvider: () => import('./views/report/station_data/Index.vue'),
			roles: RolesAny([RolesEnum.REPORTS]),
		}),
		makeParamComponentLink({
			title: 'Станции',
			name: 'StationData',
			icon: 'mdi-pipe',
			href: '/report/station-data',
			path: '/report/station-data/:utility?/:session?',
			reuseParams: true,
			componentProvider: () => import('./views/station_data/Index.vue'),
			roles: RolesAny([RolesEnum.REPORTS]),
		}),
		makeParamComponentLink({
			title: 'Имоти',
			name: 'reportImotDataList',
			icon: 'mdi-pipe',
			href: '/report/imot-list',
			path: '/report/imot-list/:utility?/:session?',
			reuseParams: true,
			componentProvider: () => import('./views/report/imot_data/Index.vue'),
			roles: RolesAny([RolesEnum.REPORTS]),
		}),
		makeParamComponentLink({
			title: 'Уреди',
			name: 'reportDevListSearch',
			icon: 'mdi-database-alert-outline',
			href: '/report/dev-list-search/',
			path: '/report/dev-list-search/:utility?/:session?/:station?',
			reuseParams: true,
			componentProvider: () => import('./views/report/deviceData/Index.vue'),
			roles: RolesAny([RolesEnum.REPORTS]),
		}),
		makeParamComponentLink({
			title: 'Документи',
			name: 'reportDoc',
			icon: 'mdi-file-document-edit-outline',
			href: '/report/docs/',
			path: '/report/docs/:utility?',
			reuseParams: true,
			componentProvider: () => import('./views/report/docs/Index.vue'),
			roles: RolesAll([RolesEnum.REPORTS]),
		}),
		makeParamComponentLink({
			title: 'Док. за одобр.',
			name: 'reportDocApprove',
			icon: 'mdi-file-document-edit-outline',
			href: '/report/doc-approve/',
			path: '/report/doc-approve/:utility?',
			reuseParams: true,
			componentProvider: () => import('./views/report/docApprove/Index.vue'),
			roles: RolesAll([RolesEnum.REPORTS, RolesEnum.OTCHETI_FULL]),
		}),
		makeParamComponentLink({
			title: 'За сваляне',
			name: 'reportDownload',
			icon: 'mdi-file-download-outline',
			href: '/report/download/',
			path: '/report/download/:utility?/:session?',
			reuseParams: true,
			componentProvider: () => import('./views/report/Download.vue'),
			roles: RolesAll([RolesEnum.REPORTS]),
		}),
	]),
	makeParamComponentLink({
		title: 'Отчети',
		name: 'account',
		icon: 'mdi-account-multiple',
		href: '/account/',
		path: "/account/:utility?/:session?/:station?/:imot?",
		reuseParams: true,
		componentProvider: () => import('./views/clients_screen/Index.vue'),
		roles: RolesAny([RolesEnum.OTCHETI_FULL,RolesEnum.OTCHETI_BASE]),
	}),
	makeParamComponentLink({
		title: 'Front desk',
		name: 'frontdesk',
		icon: 'mdi-room-service-outline',
		href: '/frontdesk/',
		path: "/frontdesk/:utility?/:session?/:station?/:imot?",
		reuseParams: true,
		componentProvider: () => import('./views/frontdesk/Index.vue'),
		roles: RolesAny([RolesEnum.FRONTDESK]),
	}),
	makeComponentLink({
		title: 'Талони пломбиране',
		icon: 'mdi-lock-outline',
		href: '/talon-plombi/',
		componentProvider: () => import('./views/talon_plombi/Index.vue'),
		roles: RolesAny([RolesEnum.TALON_PLOMBI]),
	}),
	makeParamComponentLink({
		title: 'Търсене',
		name: 'search',
		icon: 'mdi-magnify',
		href: '/search/',
		path: '/search/:utility?',
		reuseParams: true,
		componentProvider: () => import('./views/search/Index.vue'),
		roles: RolesAny([RolesEnum.SEARCH]),
	}),
	{
		route: {
			name: "reports",
			path: "/reports/:report/:session/:station",
			component: () => import('./views/reports/report1.vue'),
			props: true,
			roles: RolesAny([RolesEnum.REPORTS]),
		}
	},
	makeDefaultItem({
		title: 'Деловодство',
		icon: 'mdi-file-document-arrow-right-outline',
		href: '/tickets',
		componentProvider: () => import('./views/tickets/Index.vue'),
		roles: RolesAny([
			RolesEnum.TICKET_READONLY,
			RolesEnum.TICKET_NORMAL,
			RolesEnum.TICKET_ADMIN,
		]),
	}),
] as Item[];
